import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Flex, Box } from '@rebass/grid/emotion'
import Img from "gatsby-image"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const classes = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});



const ProductGrid = () => {
  const data = useStaticQuery(
    graphql`
        query {
          allPrismicTourpackages {
            edges {
              node {
                uid
                data {
             
                  id {
        
                    text
                  } 
                  tourtitle{
                    text
                  }
                  tourshortdescription{
                    text
                  }
                  tourprice{
                    text
                  }
                  tourimage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 500, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
           
      `
  )




  return (
    <Flex flexWrap='wrap' mx={2}>
      {data.allPrismicTourpackages.edges.map(x => (
        <Box width={[1, 1 / 2, 1 / 3]}
          px={2}
          key={x.node.id}
        >

          <Card className={classes.card}>
          
            <CardActionArea>
              <CardMedia
                className={classes.media}
                title="Contemplative Reptile"
              >
                <Img
                  fluid={x.node.data.tourimage.localFile.childImageSharp.fluid}
                />
              </CardMedia>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {x.node.data.tourtitle.text}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {x.node.data.tourshortdescription.text}
                </Typography>
                <Typography variant="h6" color="textSecondary" component="p">
                  Price: {x.node.data.tourprice.text}
                </Typography>
              </CardContent>
            </CardActionArea>
           
            <CardActions>
            <Link to={`/tour-package/${x.node.uid}/`}>
              <Button size="small" color="primary">
                Learn More
                
        </Button>
        </Link>
              <Button size="small" color="primary">
                Book it
        </Button>
            </CardActions>
          </Card>

          {/* <h1>{x.node.data.tourtitle.text}</h1>
          <Img
            fluid={x.node.data.tourimage.localFile.childImageSharp.fluid}
          />
          <h1>{x.node.data.tourshortdescription.text}</h1>
          <h1>price:{x.node.data.tourprice.text}</h1> */}
        </Box>

      ))}

      <br />
    </Flex>
  )
}
export default ProductGrid
